#list-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f4f4;
}

#list-scroll::-webkit-scrollbar-thumb {
  background-color: #2737FF ; 
  border-radius: 20px;
  width: 2px;
  
}
.Description_cols {
  width: 33% !important;
  flex-basis: 33% !important;
  max-width: 33% !important;
  float: left;
  height: auto;
}
.select-custom {
  width: 100%;
  font-size: 14px;
  padding: 6px 5px;
  height: auto;
}
.input_groups {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.Description_cols .ql-editor {
  height: 190px !important;
}
.error_message_description {
  margin: 4px 0 0;
  float: left;
  width: 100%;
}

.ql-editor{

  height: 17rem !important;
  z-index: 0 !important;
}
#dashboard-page::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f4f4;
}

#dashboard-page::-webkit-scrollbar-thumb {
  background-color: #b9b9b9 ; 
  border-radius: 15px;
  width: 2px;
  
}

#panel-sidebar::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f4f4;
  }

  #panel-sidebar::-webkit-scrollbar-thumb {
    background-color: #fff ; 
    border-radius: 20px;
    width: 2px;
    
  }
  #product-name::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f4f4;
  }
/* Spinner.css */
.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0452fa;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.maincustom-dropdonn .dropdown {
  padding: 7px 25px 7px 10px;
  background-image: url("../src/app/asset/images/caret_down_icon.png");
  background-repeat: no-repeat;
    background-size: 12px;
    background-position: 97% center;
}
.maincustom-dropdonn .custom-dropdwon-content {
  width: 100%;
  padding: 0px;
  left: -10px;
  min-width: max-content;
}
.select_item_wrapper .error_msg {
  display: none;
}
.select_item_wrapper {
  margin: 0 0 10px;
}
.select_item_wrapper .maincustom-dropdonn {
  width: 70%;
}
.product_subscription #modal-sidebar {
  overflow: visible !important;
}
.table_listing {
  display: flex;
  column-gap: 10px;
}
.table_listing ul li {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: normal;
}
.table_listing ul li span {
  color: #000;
  font-weight: 500;
}
  #product-name::-webkit-scrollbar-thumb {
    background-color: #fff ; 
    border-radius: 20px;
    
  }
  #modal-sidebar::-webkit-scrollbar {
    width: 12px;
    background-color: #ffffff;
  }

  #modal-sidebar::-webkit-scrollbar-thumb {
    background-color: #ebeaea ; 
    
  }

  .main-container{
    /* height: calc(100vh - 4.8rem); */
    overflow-y: auto; 
  }

  .main-container-app{
    height: calc(100vh - 4.1rem);
  
  }
  

  .sidebar-container{
    height: calc(100vh - 1rem);
    width: 280px !important;
    overflow-y: auto; 
  }
  .sidebar_content_wrapper {
    width: calc(100% - 280px) !important;
}

  /* Full-screen loader container */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.678); /* Optional: semi-transparent background */
  z-index: 9999; /* Ensure it is on top of other elements */
}

.loader {
  --dim: 5rem;
  width: var(--dim);
  height: var(--dim);
  border: 7px solid #2737FF;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin_51 1.5s infinite linear;
}

@keyframes spin_51 {
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}

.list-group>tr>td{
  padding-left: 1rem;
}